// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display:flex;
    width: 100%;
    position: sticky;
    justify-content: space-between;
    align-items: center;
    background-color: #979e9d;
    color: #fff;
    padding: 1rem;
    border-radius: 10px;
  }
  
  .navbar-left .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
  }
  
 `, "",{"version":3,"sources":["webpack://./src/Navbar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;IACX,qBAAqB;EACvB","sourcesContent":[".navbar {\r\n    display:flex;\r\n    width: 100%;\r\n    position: sticky;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    background-color: #979e9d;\r\n    color: #fff;\r\n    padding: 1rem;\r\n    border-radius: 10px;\r\n  }\r\n  \r\n  .navbar-left .logo {\r\n    font-size: 1.5rem;\r\n    font-weight: bold;\r\n    color: #fff;\r\n    text-decoration: none;\r\n  }\r\n  \r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
