import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const NewTask = () => {
  const [isNew, setIsNew] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [area, setArea] = useState('');

  useEffect(() => {
    async function getTask() {
      const id = params.id?.toString() || undefined;
      if (!id) return;
      setIsNew(false);
      const response = await fetch(
        `http://lulabeno.com:5000/tasks/${params.id.toString()}`
      );
      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        console.error(message);
        return;
      }
      const task = await response.json();
      if (!task) {
        navigate("/");
        return;
      }
      setName(task.name);
      setArea(task.area);
    }
    getTask();
  }, [params.id, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isNew) {
        await axios.post('http://lulabeno.com:5000/tasks', { name, area });
      } else {
        await axios.patch('http://lulabeno.com:5000/tasks/'+params.id, { name, area })
      }
      
      navigate('/AllTasks');
    } catch (err) {
      console.error(err);
    }
  };

  return (

    <div body>
   
      <form onSubmit={handleSubmit}>
       <div className="alllist"> 
      <h1>Create New Task</h1>
        <div className='input'>
          <label htmlFor="name">Task Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="area">Zone:</label>
          <select
            id="area"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            required
          >
            <option value="">Select a zone</option>
            <option value="kitchen">Kitchen</option>
            <option value="bathroom">Bathroom</option>
            <option value="living room">Living Room</option>
            <option value="bedroom">Bedroom</option>
            <option value="garage">Garage</option>
            <option value="office">Office</option>
            <option value="garden">Garden</option>
            <option value="dining room">Dining Room</option>
            
          </select>
        </div>
         <button className="button-image5"  type="submit"></button>
        </div> 
       
      </form>
    </div>
   
  );
};

export default NewTask;
