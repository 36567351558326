import React from 'react';

const Checklist = ({ mytasks, handleCheckboxChange }) => {
    return (
        <ul className="checklist">
            {mytasks.map((mytask) => (
                <li key={mytask.id}>
                    <input
                        type="checkbox"
                        id={mytask.id}
                        checked={mytask.checked}
                        onChange={() => handleCheckboxChange(mytask.id)}
                    />
                    <label htmlFor={mytask.id}>{mytask.label}</label>
                </li>
            ))}
        </ul>
    );
};

export default Checklist;