import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Popup from './components/Popup';
import TodayList from './components/TodayList';
import Title from './components/utils';
import Navbar from './Navbar';


const App = () => {
    const [popupType, setPopupType] = useState('');
    const [mytasks, setMyTasks] = useState([]);
    const [todayMyTasks, setTodayMyTasks] = useState([]);
    const [tasks, setTasks] = useState([]);
    useEffect(() => {
        async function getTasks() {
            const response = await fetch('http://lulabeno.com:5000/tasks/');
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                console.error(message);
                return;
            }
            let tasks = await response.json();
            tasks = tasks.map((task) => {
                task.area = Title(task.area);
                return task;
            })
            setTasks(tasks);
        }
        getTasks();
    }, []);




    useEffect(() => {
        const MAX_STORAGE_DURATION = 24 * 60 * 60 * 1000;

        const savedTodayMyTasks = localStorage.getItem('todayMyTasks');
        const timestamp = localStorage.getItem('todayMyTasksTimestamp');
        const now = new Date().getTime();


        if (savedTodayMyTasks && timestamp && (now - parseInt(timestamp, 10)) < MAX_STORAGE_DURATION) {
            setTodayMyTasks(JSON.parse(savedTodayMyTasks));
        } else {
            localStorage.removeItem('todayMyTasks');
            localStorage.removeItem('todayMyTasksTimestamp');
        }
    }, []);

    const handleOpenPopup = (area) => {
        let areaTasks = tasks.filter((task) => task.area === area);
        let mytaskList = areaTasks.map((task) => ({ id: task._id, label: task.name, checked: false }));
        setMyTasks(mytaskList);
        setPopupType(area);
    };

    const handleCheckboxChange = (id) => {
        setMyTasks((prevMyTasks) =>
            prevMyTasks.map((mytask) =>
                mytask.id === id ? { ...mytask, checked: !mytask.checked } : mytask
            )
        );

        setTodayMyTasks((prevTodayMyTasks) => {
            const mytaskExists = prevTodayMyTasks.find((mytask) => mytask.id === `today-${id}`);
            if (mytaskExists) {
                return prevTodayMyTasks.map((mytask) =>
                    mytask.id === `today-${id}` ? { ...mytask, checked: !mytask.checked } : mytask
                );
            } else {
                const mytaskToAdd = mytasks.find((mytask) => mytask.id === id);
                const newTodayMyTask = { id: `today-${mytaskToAdd.id}`, label: `${mytaskToAdd.label} (${popupType})`, checked: false }
                return [...prevTodayMyTasks, newTodayMyTask];
            }
        });
    };

    const handleTodayCheckboxChange = (id) => {
        let updatedMyTasks = todayMyTasks.map((myTask) => {
            if (myTask.id === id) {
                myTask.checked = !myTask.checked;
            }
            return myTask;
        })
        setTodayMyTasks(updatedMyTasks);
        localStorage.setItem('todayMyTasks', JSON.stringify(updatedMyTasks));
        localStorage.setItem('todayMyTasksTimestamp', new Date().getTime().toString());
    };

    const handleClosePopup = () => {
        setPopupType('');
    };
    const navigate = useNavigate();

    const navigateToAllTasks = () => {
        navigate('/AllTasks');
    };

    return (
        <div>
        <div>

</div>
        <div>
        <div className="container">
            <div className="button-grid">
              
                <button className="button-image" onClick={() => handleOpenPopup('Kitchen')}>
                    <img src="Kitchen.png" alt="Kitchen" />
                </button> 
                   <button className="button-image"  onClick={() => handleOpenPopup('Dining Room')}>
                    <img src="Diningroom.png" alt="Dining Room" />
                </button> 
                <button className="button-image"  onClick={() => handleOpenPopup('Living Room')}>
                    <img src="Livingroom.png" alt="Living Room" />
                </button>
                   <button className="button-image"  onClick={() => handleOpenPopup('Bedroom')}>
                    <img src="Bedroom.png" alt="Bedroom" />
                </button>
                <button className="button-image" onClick={() => handleOpenPopup('Bathroom')}>
                    <img src="Bathroom.png" alt="Bathroom" />
                </button>
                  <button className="button-image"  onClick={() => handleOpenPopup('Office')}>
                    <img src="Office.png" alt="Office" />
                </button>
             
                <button className="button-image"  onClick={() => handleOpenPopup('Garage')}>
                    <img src="Garage.png" alt="Garage" />
                </button>
             
                <button className="button-image"  onClick={() => handleOpenPopup('Garden')}>
                    <img src="Garden.png" alt="Garden" />
                </button>
            
                  <button className="button-image" onClick={navigateToAllTasks}>
                    <img src="AllTasks.png" alt="All Tasks" />
                </button>
            </div>
            <TodayList mytasks={todayMyTasks} handleCheckboxChange={handleTodayCheckboxChange} />
            {popupType && (
                <Popup
                    type={popupType}
                    mytasks={mytasks}
                    handleCheckboxChange={handleCheckboxChange}
                    closePopup={handleClosePopup}
                />
            )}
        </div>
        </div>
        </div>
    );
};



export default App;
