

import React from 'react';
import MyTask from './MyTask';

const TodayList = ({ mytasks, handleCheckboxChange }) => {
    
    return (
        
        <div className="frame-column">
        <div className="frame">
            <h2>Today</h2>
            <ul id="checklistToday">
                {mytasks.map((mytask) => (
                    <MyTask key={mytask.id} mytask={mytask} handleCheckboxChange={handleCheckboxChange} />
                ))}
            </ul>
        </div></div>
    );
};

export default TodayList;
