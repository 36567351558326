import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Title from "./utils";

const TasksList = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    async function getTasks() {
      const response = await fetch('http://lulabeno.com:5000/tasks/');
      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        console.error(message);
        return;
      }
      let tasks = await response.json();
      tasks = tasks.map((task) => {
        task.area = Title(task.area);
        return task;
      })
      setTasks(tasks); 
    }
    getTasks();
  }, []); 

  const handleDelete = async (id) => {
    if (!window.confirm("Really want to delete task?")) {
      return
    }
    try {
      await fetch(`http://lulabeno.com:5000/tasks/${id}`, { method: 'DELETE' });
      setTasks(tasks.filter(task => task._id !== id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
 <div className="alllist"> 
    <div className="create"> 
       <button className="button-image1" onClick={() => {navigate('/create')}}><img src="Create.png" alt="create"></img></button></div>
    
 
      <h1>All Tasks</h1>
         
      <table>
        <thead>
          <tr>
            <th>Zone</th>
            <th>Task</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(task => (
            <tr key={task._id}>
              <td>{task.area}</td>
              <td>{task.name}</td>
              <td>
                <button className="button-image2"  onClick={() => {navigate('/edit/'+task._id)}}></button>
              </td>
              <td>
                <button className="bdelete" onClick={() => handleDelete(task._id)}></button>
              </td>
            </tr>
          ))}
           
        </tbody>
      </table>
    </div>
  
  

 
  );
};

export default TasksList;
