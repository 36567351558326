import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import TasksList from './components/TasksList';
import NewTask from './components/NewTask';
import Navbar from './Navbar';



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/AllTasks",
    element: <TasksList />,
  },
  {
    path: "/edit/:id",
    element: <NewTask />,
  },
  {
    path: "/create",
    element: <NewTask />,
  },
]);


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
      <Navbar />
    <RouterProvider router={router} />
  </React.StrictMode>
);
