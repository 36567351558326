import React from 'react';

const MyTask = ({ mytask, handleCheckboxChange }) => {
    return (
        <li>
            <input
                type="checkbox"
                id={mytask.id}
                checked={mytask.checked}
                onChange={() => handleCheckboxChange(mytask.id)}
            />
            <label
                htmlFor={mytask.id}
                className={mytask.checked ? 'strikethrough' : ''}
            >
                {mytask.label}
            </label>
        </li>
    );
};

export default MyTask;
