import React from 'react';
import './Navbar.css';

const Navbar = () => {
  return (

<nav className="navbar">
  <div className="navbar-left">
    <a href="/" className="logo">
      Home
    </a>
  </div>
  
</nav>
);
};

export default Navbar;